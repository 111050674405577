import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import * as $ from "jquery";
import "jquery-ui/ui/widgets/sortable.js";
import { SidebarContentComponent } from "../..";
import "./jq.js";
import { SidebarRoutingOptions } from "./sidebar-routing";

@Component({
    selector: "app-sidebar-routing",
    templateUrl: "./sidebar-routing.component.html",
    styleUrls: ["./sidebar-routing.component.scss"],
})
export class SidebarRoutingComponent implements SidebarContentComponent<SidebarRoutingOptions>, OnInit {
    @ViewChild("list", { static: true }) list;

    faSort = faSort;
    options: SidebarRoutingOptions;
    currentFragment: string;
    sortableArray: any = [];
    notSortableArray: any = [];



    constructor(route: ActivatedRoute) {
        route.fragment.subscribe((a) => (this.currentFragment = a));
    }

    ngOnInit(): void {
        if (!this.options.aclOptions) {
            this.options.aclOptions = {
                create: 0,
                delete: 0,
                edit: 0,
            };

        }

        this.options.data.forEach(data => {
            if(this.options.dataDontMove.map((d) => d.id).includes(data.id)){
                this.notSortableArray.push(data);
            } else {
                this.sortableArray.push(data);
            }
        });

        if (this.options.aclOptions.edit || this.options.aclOptions.movable) {
            this.initSortable();
        }


    }

    onDrop() {
        if (this.options.move) {
            const positions = this._buildPositionArray();
            this.options.move(positions);
        }
    }

    private initSortable(refreshMode = false) {
        window.setTimeout(() => {
            refreshMode
                ? (<any>$(".sortable_items")).sortable("refresh", { handle: ".handle", update: this.onDrop.bind(this) })
                : (<any>$(".sortable_items")).sortable({ handle: ".handle", update: this.onDrop.bind(this) });

            $(".nav-item").each(function () {
                $(this).addClass("ui-sortable-handle");
            });
        }, 3000);
    }

    _buildPositionArray() {
        const position = [];
        this.list.nativeElement.children[0].children.forEach((item) => {
            if (item.id) position.push(item.id);

        });
        return position;
    }

}
