import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MaestroPage } from "app/shared/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments";
import { TemplateService } from "../template/template.service";
import { PageService } from "./page.service";

@Injectable()
export class FlatplanViewService {
    constructor(private _page: PageService, private _template: TemplateService, private _http: HttpClient) {}

    /**
     * Create a new flatplan
     *
     * @param idExport
     * @param nbPagesCdf
     * @returns
     */
    createCdf(idExport: number, nbPagesCdf: number): Observable<any> {
        return this._page.createCdf(idExport, nbPagesCdf);
    }

    /**
     * Get a flatplan by id
     * @param id
     * @returns
     */
    getById(id: number, version?: number): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/cdf/${id}.json`, { version: version }).pipe(
            map((data: any) => {
                return {
                    pages: data.data.pages && data.data.pages.pages ? data.data.pages.pages.map((page) => this.serializePage(page)) : [],
                    covers: data.data.pages && data.data.pages.covers ? data.data.pages.covers.map((page) => this.serializePage(page)) : [],
                    products: data.data.products,
                };
            })
        );
    }

    /**
     * Save a flatplan
     *
     * @param id
     * @param ids
     * @returns
     */
    saveFlatplan(id: number, ids: number[]): Observable<any> {
        return this._http.put(`${environment.projectsUrl}/cdf/${id}.json`, ids);
    }

    /**
     * Update a page
     *
     * @param idCdf
     * @param idPage
     * @param value
     * @returns
     */
    updatePage(idCdf: number, idPage: string, value: any): Observable<any> {
        return this._page.updatePage(idCdf, idPage, value);
    }

    /**
     * Get a template list
     *
     * @returns
     */
    getTemplates() {
        return this._template.getAll();
    }

    /**
     * Get cdf id
     * @param exportId
     * @returns
     */
    getCdfId(exportId: number): Observable<any> {
        return this._page.getIdCdf(exportId);
    }

    /**
     * Delete a group of pages
     *
     * @param optionId
     * @returns
     */
    deleteAllPage(optionId: string): Observable<any> {
        return this._http.delete(`${environment.projectsUrl}/page/option/${optionId}.json`);
    }

    /**
     * Delete a duplicate of pages
     *
     * @param duplicateId
     * @returns
     */
    deleteDuplicatePage(duplicateId: number): Observable<any> {
        return this._http.delete(`${environment.projectsUrl}/page/duplicate/${duplicateId}.json`);
    }

    /**
     * Get export elements by project id
     * @param projectId
     * @returns
     */
    getExportElementsOld(projectId: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/project/${projectId}/exports.json`);
    }

    /**
     * Get export elements by export id
     * @param exportId
     * @returns
     */
    getExportElements(exportId: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/export/${exportId}/elements.json`);
    }

    /**
     * Get cdf info
     *
     * @param cdfId
     * @returns
     */
    getInfoCdf(cdfId: number): Observable<any> {
        return this._http.get(`${environment.projectsUrl}/export/${cdfId}/nbPage.json`);
    }

    private serializePage(pageResources) {
        return <MaestroPage>{
            id: pageResources.id,
            status: pageResources.status,
            template: { name: pageResources.template },
            height: pageResources.height,
            width: pageResources.width,
            heading: pageResources.pageDuplicateId ? pageResources.pageDuplicateId : pageResources.option,
            pageLength: pageResources.nbPages,
            elementLength: pageResources.nbProducts,
            elements: pageResources.products || [],
            group: pageResources.option,
            name: pageResources.name,
            folio: pageResources.folio,
            exist: pageResources.exist,
            index: pageResources.index,
            pageDuplicateId: pageResources.pageDuplicateId,
            previews: pageResources.previews,
            templateId: pageResources.templateId,
            isImport: pageResources.isImport,
            cover : pageResources.cover
        };
    }

    /**
     * Get templates available for a page
     * @param id
     * @param pageLength
     * @param elementLength
     * @returns
     */
    getTemplatesForPage(id: number, pageLength: number, elementLength: number): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/page/export/${id}/templates.json`, { pageLength: pageLength, elementLength: elementLength });
    }
}
