<div class="row px-4">
    <div class="ml-2 mr-2" *ngFor="let place of places">
        <div class="card" style="max-width: 10rem; min-width: 10rem;">
            <div class="card-header">{{ place.name }}</div>
            <div *ngIf="place.mediaId === -1 && !disabled" class="card-body text-center btn-group">
                <button *ngIf="activatedModules.dam" type="button" class="btn btn-outline-primary"
                    (click)="openModal(place)">
                    <fa-icon [icon]="faSearch"></fa-icon>
                </button>
                <button class="btn btn-outline-primary" (click)="openImportModal(place)">
                    <fa-icon [icon]="faPlus"></fa-icon>
                </button>
            </div>
            <div *ngIf="place.isExpired" class="px-2 py-1 mt-5 mr-2 position-absolute left-0 rounded card_expired">
                {{ "dam.expire" | translate | uppercase }}
            </div>
            <!-- <img *ngIf="place.mediaId !== -1" [src]="getMediaImg(place)" [style]="'transform: rotate('+place.rotate+'deg) scaleX('+(place.flipHorizontal ? -1 : 1)+') scaleY('+(place.flipVertically ? -1 : 1)+')'" class="card-img-top" alt="Card media cap" /> -->
            <img *ngIf="place.mediaId !== -1" [src]="getMediaImg(place)"
                class="card-img-top card-body-img-fixed-height maestro-background-checkerboard"  [ngStyle]="{'border': place.isExpired ? '1px red solid' : ''}" [title]="place.isExpired ? _translate.instant('dam.expired') + place.dateValid :''  | translate"/>
            <div class="card-footer" *ngIf="!disabled">
                <div *ngIf="place.mediaId !== -1" class="btn-group float-right row" role="group">
                    <a *ngIf="place.positionPhotoHeight > 0 && place.positionPhotoWidth > 0" type="button"
                        class="btn btn-outline-warning col-3" (click)="openCrop(place)">
                        <fa-icon [icon]="faCrop"></fa-icon>
                    </a>

                    <div *ngIf="place.positionPhotoHeight <= 0 || place.positionPhotoWidth <= 0"
                        pTooltip="{{ 'projects.template.noPosPhotoSize' | translate }}">
                        <button type="button" class="btn btn-outline-warning" disabled>
                            <fa-icon [icon]="faCrop"></fa-icon>
                        </button>
                    </div>

                    <button type="button" class="btn btn-outline-primary col-3" (click)="changeMedia(place)">
                        <i class="fas fa-photo-video"></i>
                    </button>
                    <button type="button" class="btn btn-outline-info col-3" (click)="openFieldModal(place)">
                        <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                    <button type="button" class="btn btn-outline-danger col-3" (click)="unselected(place)">
                        <fa-icon [icon]="faTrashAlt"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-cropper #cropper (cropped)="cropMedia($event)" (removeCrop)="removeCrop()" [module]="'projects'"></app-cropper>

<swal #mediaSwal [width]="'95%'" title="{{ 'projects.select' | translate }}" [showCancelButton]="true"
    [cancelButtonColor]="'#FF0000'" cancelButtonText="{{ 'general.close' | translate }}" [showConfirmButton]="false"
    (cancel)="resetSelectedMedias()" (close)="resetSelectedMedias()">
    <div *swalPortal class="row px-4">
        <!-- <div class="ml-2 mr-2" *ngFor="let media of medias">
            <div class="card" style="max-width: 10rem; min-width: 10rem;">
                <div class="card-header text-truncate">{{ media.name }}</div> -->
        <!-- <img [src]="'data:image/png;base64,' + media.thumb" class="card-img-top" [style]="'transform: rotate('+place.rotate+'deg) scaleX('+(place.flipHorizontal ? -1 : 1)+') scaleY('+(place.flipVertically ? -1 : 1)+')'" alt="Card media cap" /> -->

        <!-- <img [src]="media.thumbPath ? damUrl+media.thumbPath : 'data:image/png;base64,' + media.thumb"
                    class="card-img-top card-body-img-fixed-height maestro-background-checkerboard"
                    alt="Card media cap" />
                <div class="card-footer justify-content-end">
                    <div class="btn-group text-right" role="group">
                        <button type="button" class="btn btn-primary" (click)="selectMedia(media)">
                            <fa-icon [icon]="faHandPointer"></fa-icon>
                        </button>
                        <a type="button" class="btn btn-warning" (click)="openCrop(currentPlace, media)">
                            <fa-icon [icon]="faCrop"></fa-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div> -->
        <div *ngIf="filters.tags.length" class="tags mb-1">
            <span class="mr-1">{{ 'filter.tag' | translate }} : </span>

            <span *ngFor="let tag of filters.tags" class="badge badge-pill badge-primary mr-1">
                <span class="mr-1">{{ tag.name }}</span>
                <span class="pointer" (click)="manageFilter(tag, false)">x</span>
            </span>
        </div>
        <div *ngIf="filters.type.length" class="mb-1">
            <span class="mr-1">{{ 'filter.extension' | translate }} : </span>

            <span *ngFor="let ext of filters.type" class="badge badge-pill badge-primary mr-1">
                <span class="mr-1">{{ ext.id }}</span>
                <span class="pointer" (click)="manageFilter(ext, false, 'type')">x</span>
            </span>
        </div>
        <app-dataview titleNotTooltip="pim.medias" type="page" [dataviewContent]="medias"
            (lazyEvent)="getMedias($event)" (searchEvent)="loadListWithSearch($event)" [minified]="true"
            (selectMedia)="selectMedia($event)" (openCrop)="openCropMedia($event)"
            [events]="eventsSubject.asObservable()" [totalRecords]="recordsFiltered" [buttons]="buttons"
            [sortOptions]="sortOptions" [poppedUp]="true" (tagFiltered)="manageFilter($event)"
            (filterExpireMedia)="changeHideExpireMedia($event)"
            (selectExtension)="manageFilter($event, true, 'type')" style="width: 100%;">
        </app-dataview>
    </div>
</swal>

<swal #importSwal title="{{ 'export.import' | translate }}" [showCancelButton]="true" [cancelButtonColor]="'#FF0000'"
    cancelButtonText="{{ 'general.close' | translate }}" confirmButtonText="{{ 'general.import' | translate }}">
    <div *swalPortal class="row px-4">
        <div class="custom-file">
            <input type="file" class="custom-file-input" id="importedFile"
                (change)="handleFileInput($event.target.files)" required>
            <label class="custom-file-label" for="importedFile">{{ "general.choose.file" | translate }}</label>
        </div>
    </div>
</swal>

<swal #fieldSwal [width]="'50%'" title="{{ 'export.information' | translate}}" [showCancelButton]="true"
    [cancelButtonColor]="'#FF0000'" cancelButtonText="{{ 'general.close' | translate }}" [showConfirmButton]="true">
    <div *swalPortal class="row">
        <div style="height: 30%;" class="custom-file">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-11 form-group d-flex p-0">
                        <label class="col-3">Copyright</label>
                        <input class="form-control col-9" type="text" formControlName="copyright">
                    </div>
                </div>
                <div class="row">
                    <div class="col-11 form-group d-flex p-0">
                        <label class="col-3">{{ 'dam.legend' | translate }}</label>
                        <input class="form-control col-9" type="text" formControlName="legend">
                    </div>
                </div>
                <div *ngIf="form && form.value.dateValid" class="row">
                    <div class="col-11 form-group d-flex p-0">
                        <label class="col-3">{{ 'dam.dateValid' | translate }} : </label>
                        <input class="form-control col-9" type="text" formControlName="dateValid" disabled="disabled">
                    </div>
                </div>
            </form>
        </div>
    </div>
</swal>
