import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { EditFieldsetComponent } from "./components/edit-fieldset/edit-fieldset.component";
import { EditListMultipleComponent } from "./components/edit-list-multiple/edit-list-multiple.component";
import { EditProductLinkComponent } from "./components/edit-product-link/edit-product-link.component";
import { EditTableComponent } from "./components/edit-table/edit-table.component";
import { PageTextTabComponent } from "./page-text-tab.component";

@NgModule({
    declarations: [PageTextTabComponent, EditFieldsetComponent, EditListMultipleComponent, EditProductLinkComponent, EditTableComponent],
    imports: [SharedModule, SharedComponentsModule],
    exports: [PageTextTabComponent],
})
export class PageTextTabModule {}
