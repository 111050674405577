import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "filterType",
})
export class FilterTypePipe implements PipeTransform {
    transform(fieldTypes: string[], fieldType: string, isFilterable: boolean): string[] {
        if (!isFilterable) {
            return fieldTypes;
        }
        let authorized: string[] = [];
        switch (fieldType) {
            case "text":
                authorized = ["text", "textarea"];
                break;
            case "textarea":
                authorized = ["textarea"];
                break;
            case "number":
                authorized = ["number", "text", "textarea"];
                break;
            case "checkbox":
                authorized = ["checkbox"];
                break;
            case "list":
                authorized = ["list"];
                break;
            case "date":
                authorized = ["date", "text", "textarea"];
                break;
            case "fieldset":
                authorized = ["fieldset"];
                break;
            case "linkedProduct":
                authorized = ["textarea"];
                break;
            case "colors":
                authorized = ["colors"];
                break;
            case "table":
                authorized = ["table"];
                break;
            case "list multiple":
                authorized = ["list multiple"]
            default:
                return fieldTypes;
        }
        fieldTypes = fieldTypes.filter((f) => authorized.includes(f));
        return fieldTypes;
    }
}
