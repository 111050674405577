import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MaestroTags } from "app/shared/models";
import { Thumb } from "app/shared/models/thumb";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments";
import { TagService } from "../tag/tag.service";

@Injectable()
export class ThumbnailsService {
    private medias: BehaviorSubject<Thumb[]> = new BehaviorSubject<Thumb[]>([]);
    private elementMedias: BehaviorSubject<Thumb[]> = new BehaviorSubject<Thumb[]>([]);
    media: BehaviorSubject<Thumb> = new BehaviorSubject<Thumb>(null);

    constructor(private _http: HttpClient, private _tagService: TagService) { }



    setMediaParam(mediaParam: Thumb) {
        this.media.next(mediaParam);
    }

    /**
     * Get all media's
     * @returns
     */
    getAll(): Observable<Thumb[]> {
        return this._http.get<Thumb[]>(`${environment.damUrl}/media.json`);
    }

    /**
     * Update a media
     *
     * @param mediaId
     * @param file
     * @param isPicto
     * @returns
     */
    updateMedia(mediaId: number, file: File, isPicto: boolean): Observable<any> {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("picto", isPicto + "");
        return this._http.post(`${environment.damUrl}/upload/${mediaId}.json`, formData);
    }

    getMedias(): Observable<Thumb[]> {
        return this.medias.asObservable();
    }

    getElementMedias(): Observable<Thumb[]> {
        return this.elementMedias.asObservable();
    }

    /**
     * Fetch medias
     * @returns
     */
    getImages() {
        return this.getAll().subscribe((data: any) => {
            const values = [];
            for (const dataKey of data.data) {
                values.push(new Thumb().deserialize(dataKey));
            }
            this.medias.next(values);
        });
    }

    sendValuesObservables(values) {
        this.elementMedias.next(values);
        this.medias.next(this.medias.value);
    }

    /**
     * Delete a media
     * @param id
     */
    deleteMedia(id: number): Observable<any> {
        return this._http.delete(`${environment.damUrl}/media/${id}.json`);
    }

    getSidebarData(url: string): Observable<MaestroTags> {
        return this._tagService.getSidebarTags(url);
    }

    getDataviewMedias(filter: any): Observable<any> {
        return this._http.post(`${environment.damUrl}/dataview/media.json`, filter);
    }
}
