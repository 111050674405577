import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private _authService: AuthService, private modal: SwalModalService, private translate: TranslateService, private _toaster: ToastService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap((response: HttpResponse<any>) => {
                // TODO: When we'll have the notification service we'll handle the response
            }),
            catchError((error: HttpErrorResponse) => {
                return this._errorTreatment(error, request.method);
            })
        );
    }

    private async _errorTreatment(httpError: HttpErrorResponse, httpVerb: string): Promise<any> {
        const url = httpError.url;

        if (this._authService.showError$.value) {
            let error;

            if (httpError.error instanceof Blob) {
                error = JSON.parse(await (httpError.error as any).text());
            } else {
                error = httpError.error;
            }

            const status = httpError.status;
            const key = error.key;
            const method = error.method;
            const type = error.type;

            if (401 === +status && !url.includes("/sign_in")) {
                return this._authService.logout(true); // Return to redirect
            } else {
                if (url && url.endsWith("graphql/graphql.json") && status === 0) {
                    console.log("Dashboard disabled");
                } else if ((key === "findGenrationData" && method === "getGenerationData") || (key === "generate" && method === "generate")) {
                    this._toaster.show({ type: "info", message: this.translate.instant(`response.error.${key}.${method}`) });
                } else {
                    let keyBase = "";
                    let keyFull = "";
                    let message = "";

                    if (url && url.endsWith("graphql/graphql.json")) {
                        message = error.errors[0].message;
                    } else {
                        message = error.data.split("Class :")[0].trim();
                    }

                    const responseParameters = (undefined !== (key && type && method) && "undefined" !== (key && type && method) && "" !== (key && type && method)) ?? false;

                    if (responseParameters) {
                        keyBase = "response." + type + "." + key;
                        keyFull = keyBase + "." + method;

                        if (403 === +status) {
                            const customMessage = this.translate.instant(keyBase + ".required", { data: message });
                            message = this.translate.instant(keyFull, { data: customMessage });
                        } else if (key === "twig" && method === "render") {
                            message = this.translate.instant(keyFull, { data: message }) + " :  " + error.data;
                        } else if (key === "server" && method === "connect") {
                            message = this.translate.instant(keyFull);
                        } else {
                            message = this.translate.instant(keyFull, { data: message });
                        }
                    } else {
                        switch (httpVerb) {
                            case "GET":
                            case "POST":
                            case "PUT":
                            case "DELETE":
                                const translation = this.translate.instant(["response.error.http.base", `response.error.http.verb.${httpVerb.toLocaleLowerCase()}`, "response.error.http.ressource"]);
                                message = Object.values(translation).join(" ");
                                break;
                            default:
                                message = this.translate.instant("response.error.internal");
                        }
                    }

                    this.modal.error(message);
                }

                return throwError(httpError).toPromise();
            }
        } else {
            return new Promise((resolve, reject) => {
                reject(true);
            });
        }
    }
}
