import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { DataViewModule } from "primeng-lts/dataview";
import { CropperModule } from "../cropper/cropper.module";
import { EditFormModule } from "../dam-metadata/edit-form.module";
import { DropDownModule } from "../form/drop-down/drop-down.module";
import { TreeTagModule } from "../massAction/tree/tag/treeTag.module";
import { DataviewComponent } from "./dataview.component";
import { DataviewResolver } from "./dataview.resolver";
import { GridComponent } from "./grid/grid.component";
import { PreviewComponent } from "./preview/preview.component";
import { ThumbComponent } from "./thumb/thumb.component";

@NgModule({
    declarations: [DataviewComponent, ThumbComponent, PreviewComponent, GridComponent],
    imports: [DataViewModule, SharedModule, CropperModule, DropDownModule, TreeTagModule, EditFormModule],
    providers: [DataviewResolver],
    exports: [DataviewComponent],
})
export class DataviewModule {}
