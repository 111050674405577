<div class="tableComponent row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body table-responsive">
                <h3 class="text-primary text-center">{{ (isFlatplan ? 'projects.flatplan.list' : 'projects.flatplan.listExp') | translate }}<span *ngIf="this.versionName"> - {{ versionName }}</span></h3>
                <div *ngIf="_selectedRows.length" class="col text-right">
                    <button id="cart" class="btn btn-maestro-yellow" data-toggle="modal" data-target="#cartModal"
                        [disabled]="!_selectedRows.length">
                        {{ "datatable.selection" | translate }}
                        <span *ngIf="_selectedRows.length"
                            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{
                            _selectedRows.length }}</span>
                    </button>
                </div>
                <hr>
                <div class="row mt-2 mb-2">
                    <div class="col-md-12 text-center">                        
                        <ng-container *ngTemplateOutlet="exportButtons"></ng-container>
                    </div>
                </div>
                <p-table styleClass="p-datatable-striped" [value]="groups" dataKey="id" (onRowReorder)="sort($event)" [(selection)]="_selectedRows">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 1rem">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th *ngFor="let header of headers">{{ header }}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-page let-expanded="expanded" let-index="rowIndex">
                        <tr [pReorderableRow]="index">
                            <td class="pt-2">
                                <p-tableCheckbox [value]="page"></p-tableCheckbox>
                            </td>
                            <td>
                                <i class="pi pi-bars" pReorderableRowHandle></i>
                                <button type="button" pRipple [pRowToggler]="page" class="btn mb-1 mx-2 p-0" [ngClass]="expanded ? 'pi pi-fw pi-chevron-circle-down' : 'pi pi-fw pi-chevron-circle-right'"></button>
                                <span [routerLink]="page.template && page.template.name ? ['..', 'page', page.id] : null" [ngClass]="page.template && page.template.name ? ['pointer', 'text-primary'] : none">{{ '' != page.name ? page.name : 'table.templates.noPageName' | translate }}</span>
                            </td>

                            <td>
                                <div>
                                    <ng-container *ngIf="page.elements.length > 5 && !elementStates[page.id]; else elseElementBlock">
                                        <span *ngFor="let element of page.elements.slice(0, 5)">
                                          <a *ngIf="acl.SYSPAD_PIM_ELEMENT_READ; else elseBlockElement" href="{{ '/pim/element/view/' + element.id }}" target="_blank">{{ element.name }}</a><br>
                                          <ng-template #elseBlockElement>
                                            {{ element.name }}<br>
                                          </ng-template>
                                        </span>
                                        <div (click)="elementStates[page.id] = !elementStates[page.id]" class="pointer text-center text-muted">
                                            <span  class="pi pi-fw " [ngClass]="(elementStates[page.id] === true) ? 'pi-chevron-circle-up' : 'pi-chevron-circle-down'"></span>
                                            <span *ngIf="!elementStates[page.id]">{{ "general.seeMore" | translate }}</span>
                                            <span *ngIf="elementStates[page.id]">{{ "general.seeLess" | translate }}</span>
                                        </div>
                                    </ng-container>
                                    <ng-template #elseElementBlock>
                                        <span *ngFor="let element of page.elements">
                                          <a *ngIf="acl.SYSPAD_PIM_ELEMENT_READ; else elseBlockElement" href="{{ '/pim/element/view/' + element.id }}" target="_blank">{{ element.name }}</a><br>
                                          <ng-template #elseBlockElement>
                                            {{ element.name }}
                                          </ng-template>
                                        </span>
                                        <div *ngIf="elementStates[page.id]" (click)="elementStates[page.id] = !elementStates[page.id]" class="pointer text-center text-muted">
                                            <span  class="pi pi-fw " [ngClass]="(elementStates[page.id] === true) ? 'pi-chevron-circle-up' : 'pi-chevron-circle-down'"></span>
                                            <span *ngIf="!elementStates[page.id]">{{ "general.seeMore" | translate }}</span>
                                            <span *ngIf="elementStates[page.id]">{{ "general.seeLess" | translate }}</span>
                                        </div>
                                    </ng-template>
                                </div>
                            </td>

                            <td>
                                <a *ngIf="acl.MAESTRO_PROJECT_TEMPLATE_UPDATE; else elseBlockTemplate" href="{{ '/projects/templates/' + page.templateId + '/update' }}" target="_blank">{{ page.template.name }}</a>
                                <ng-template #elseBlockTemplate>
                                    {{ page.template.name }}
                                </ng-template>
                            </td>
                            <td>{{ page.nbpage }}</td>
                            <td *ngIf="isFlatplan">
                                {{ page.folio.start }}<ng-container *ngIf="page.folio.start !== page.folio.end"> - {{ page.folio.end }}</ng-container>
                            </td>
                            <td>
                                <app-page-thumbnail-menu *ngIf="page.template && page.template.name && '' !== page.template.name; else elseBlock" (menuClick)="onAction($event, page)" [menu]="items"></app-page-thumbnail-menu>
                                <ng-template #elseBlock>
                                    <app-page-thumbnail-menu (menuClick)="onAction($event, page)" [menu]="itemsWithoutEdit"></app-page-thumbnail-menu>
                                </ng-template>
                            </td>
                        </tr>
                        <tr *ngIf="groups.length === 0">
                            <td class="text-center font-weight-bold" colspan="7">
                                {{ "general.no.data" | translate }}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-page>
                        <tr>
                            <td [attr.colspan]="headers.length">
                                <div *ngIf="page.previews && page.previews.length; else elseBlockData" class="card-deck" style="height: 140px;">
                                    <button pButton pRipple type="button" icon="pi pi-eye" class="p-button-sm p-button-secondary p-button-rounded p-button-outlined text-primary" (click)="onPreview(page)"></button>
                                        <div *ngFor="let preview of page.previews" class="card"
                                            style="max-width: 100px; max-height: 100px;">
                                            <img [src]="'data:image/jpg;base64,' + preview"
                                                class="maestro-background-checkerboard shadow" alt="preview"/>
                                        </div>
                                </div>
                                <ng-template #elseBlockData>
                                    <div>
                                        {{ 'datatable.emptySubData' | translate }}
                                    </div>
                                </ng-template>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<!-- Template Mass Buttons Local -->
<ng-template #exportButtons>
    <div class="inline" *ngIf="_selectedRows.length">
        <button type="button" pButton pRipple
            (click)="getIdsForArchive()"
            class="btn btn-info text-white mr-1"><i class="pi pi-download"></i><span class="ml-2">{{
                'projects.export.generation.downloadArchive' | translate }} {{ 'projects.export.generation.archiveBd' | translate }}</span></button>
        <button type="button" pButton pRipple
        (click)="getIdsForArchive(true)"
        class="btn btn-info text-white mr-1"><i class="pi pi-download"></i><span class="ml-2">{{
            'projects.export.generation.downloadArchive' | translate }} {{ 'projects.export.generation.archiveHd' | translate }}</span></button>
    </div>
</ng-template>

<!-- Selection Modal Local -->
<div class="modal fade" id="cartModal" tabindex="-1" role="dialog" aria-labelledby="cartModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="cartModalLabel">{{ "datatable.selection" | translate }} : {{
                    _selectedRows.length }} {{ 'projects.project' | translate }}(s)</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ul class="list-unstyled">
                    <div *ngIf="_selectedRows.length">
                        <li *ngFor="let row of _selectedRows" class="pb-2 pt-2">
                            <div class="row">
                                <div class="col-8 text-truncate mt-3">
                                    <span class="font-weight-bold">{{ row.heading }} </span>
                                </div>
                                <div class="col-4 mt-2 text-right">
                                    <button (click)="removeFromSelection(_selectedRows, row.heading)" class="ml-auto btn btn-sm-danger">
                                        <i class="pi pi-minus-circle"></i>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </div>
                    <div *ngIf="!_selectedRows.length">
                        {{ "datatable.emptySelection" | translate }}
                    </div>
                </ul>
            </div>
            <div class="modal-footer">
                <ng-container *ngTemplateOutlet="exportButtons"></ng-container>
            </div>
        </div>
    </div>
</div>
