<div class="rounded-lg">
    <div class="bg-brand p-2 rounded-top d-flex navbar">
        <span class="text-white h4 m-0">{{ 'pim.media' | translate }}</span>
        <!-- @TODO: See if we keep this kind of search or not -->
        <!--<div class="col-sm-4 input-group">
            <input class="form-control" debouncetime="1500" placeholder="{{ 'general.search' | translate }}" type="text"
                (keyup)="searchLinkMedias($event.target.value)" />
            <div class="input-group-append">
                <div class="input-group-text">
                    <fa-icon class="ng-fa-icon" [icon]="faSearch">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search"
                            class="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512">
                            <path fill="currentColor"
                                d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z">
                            </path>
                        </svg>
                    </fa-icon>
                </div>
            </div>
        </div>-->
    </div>
    <div class="bg-white p-2">
        <app-dataview *ngIf="mediasLinkFiltered.length" titleNotTooltip="pim.medias" [filtered]="true" type="pim"
            [dataviewContent]="mediasLinkFiltered" [buttons]="buttons" (unlinked)="unlinkMedia($event)" [lazy]="false"
            [events]="eventsSubject.asObservable()" (filterExpireMedia)="changeHideExpireMedia($event)">
        </app-dataview>
        <span *ngIf="!mediasLinkFiltered.length">{{ 'general.no.data' | translate }}</span>
    </div>
</div>

<swal #createModal [width]="'90%'" title="{{ 'modal.media' | translate }}"
    confirmButtonText="{{ 'modal.link' | translate }}" (confirm)="addSelectedMedias()">
    <div *swalPortal class="modal-body">
        <div *ngIf="filters.tags.length" class="tags mb-1">
            <span class="mr-1">{{ 'filter.tag' | translate }} : </span>

            <span *ngFor="let tag of filters.tags" class="badge badge-pill badge-primary mr-1">
                <span class="mr-1">{{ tag.name }}</span>
                <span class="pointer" (click)="manageFilter(tag, false)">x</span>
            </span>
        </div>
        <div *ngIf="filters.type.length" class="mb-1">
            <span class="mr-1">{{ 'filter.extension' | translate }} : </span>

            <span *ngFor="let ext of filters.type" class="badge badge-pill badge-primary mr-1">
                <span class="mr-1">{{ ext.id }}</span>
                <span class="pointer" (click)="manageFilter(ext, false, 'type')">x</span>
            </span>
        </div>
        <app-dataview [dataviewContent]="mediasModal" (lazyEvent)="loadList($event)" [sortOptions]="sortOptions"
            [totalRecords]="recordsFiltered" [minified]="true" [selectable]="true" [poppedUp]="true"
            [selectedMedias]="selectedMedias" [linkedMedias]="elementMedias" (searchEvent)="loadListWithSearch($event)"
            (selectMedia)="selectMedia($event)" (tagFiltered)="manageFilter($event)" (filterExpireMedia)="changeHideExpireMedia($event)"
            (selectExtension)="manageFilter($event, true, 'type')" [first]="currentDatatableFilters.first ? currentDatatableFilters.first : 0">
        </app-dataview>
    </div>
</swal>