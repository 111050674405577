import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class PageTextTabService {
    constructor(private _http: HttpClient) {}

    /**
     * Save a value
     *
     * @param value
     * @param export_id
     * @param idProject
     * @param idElemTypeField
     * @param idProduct
     * @param templateFieldId
     * @param idPage
     * @param version
     * @param valuePimId
     * @param fieldTemplateTwigId
     * @param collection
     * @param pimInputId
     * @returns
     */
    saveValue(
        value: any,
        export_id: number,
        idProject: any,
        idElemTypeField: any,
        idProduct: any,
        variableProjectId: any,
        idPage: any,
        version: any,
        pageDuplicateId: any,
        valuePimId?: any,
        fieldTemplateTwigId?: any,
        collection?: any,
        pimInputId?: any,
        isAddCollection?: boolean
    ): Observable<any> {
        const formData = new FormData();
        formData.append("value", value);
        formData.append("id", idProject || 0);
        formData.append("pimElementTypeFieldId", idElemTypeField);
        formData.append("pimElementId", idProduct);
        formData.append("variableProjectId", variableProjectId);
        formData.append("pageId", idPage);
        formData.append("inputId", pimInputId);
        formData.append("version", version);
        formData.append("pageDuplicateId", pageDuplicateId);
        formData.append("_method", "PUT");

        if (valuePimId && !isAddCollection) {
            formData.append("valuePimId", valuePimId);
        }
        if (fieldTemplateTwigId) {
            formData.append("templateInputId", fieldTemplateTwigId);
        }
        if (collection) {
            formData.append("collection", collection);
        }
        return this._http.post<any>(`${environment.projectsUrl}/export/${export_id}/value.json`, formData, {
            observe: "response" as "body",
            responseType: "json",
        });
    }

    /**
     * Get history
     *
     * @param objectId
     * @returns
     */
    getHistory(objectId: number): Observable<any> {
        return this._http.get(`${environment.historisationUrl}/story/element_value/project/update/${objectId}.json`);
    }

    /**
     * Get value from pim
     * @param parentId Can be an element or a collection id
     * @param fieldId Can be an element type field or input id
     * @param isFieldset
     * @returns
     */
    getPimValue(parentId: number, fieldId: number, isFieldset: boolean): Observable<any> {
        return this._http.post(`${environment.pimUrl}/element_value/read.json`, { parentId: parentId, fieldId: fieldId, isFieldset: isFieldset });
    }

    /**
     * Get field from pim
     * @param fieldId
     * @param isFieldset
     * @returns
     */
    getPimField(fieldId: number, isFieldset: boolean): Observable<any> {
        return this._http.post(`${environment.pimUrl}/field/${fieldId}.json`, { isFieldset: isFieldset });
    }

    getElementValue(elementId: number, pageId: number, variableId: number, layoutId: number): Observable<any> {
        return this._http.post(`${environment.projectsUrl}/element/${elementId}/value.json`, { page: pageId, variableId: variableId, layoutId: layoutId })
    }
}
