import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { DataService } from "app/core/services/global/data/data.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { ProjectExportService } from "app/core/services/project/export/export.service";
import { PageService } from "app/core/services/project/export/page.service";
import { ACL } from "app/shared/models/acl";
import { ThumbnailMenu, ThumbnailMenuAction } from "app/shared/models/project/thumbnail";
import { environment } from "environments/environment";

@Component({
    selector: "app-pages-list",
    templateUrl: "./pages-list.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagesListComponent implements OnInit, OnDestroy, AfterViewChecked {
    @Input() pages: any[] = [];
    @Input() versionName: string = "";
    pagesBase: any[] = [];
    groups: any[] = [];
    @Input() activePanel: "image" | "info";
    @Input() isFlatplan: boolean = false;

    @Output() movePage: EventEmitter<number[]> = new EventEmitter(); // array of page id
    @Output() pageAction: EventEmitter<any> = new EventEmitter();

    selectedHeadings = {};

    items: ThumbnailMenu = [];

    // @TODO: Rework this part
    itemsWithoutEdit: ThumbnailMenu = [];

    exportType = 2;

    previews = [];
    projectsUrl = environment.projectsUrl;
    readonly faEye = faEye;

    headers = [];
    acl: ACL;
    showAllElements: boolean = false;
    elementStates: boolean[] = [];

    _selectedRows: any[] = []; // Define which rows are selected

    constructor(
        private _authService: AuthService,
        private _layout: LayoutService,
        private _translate: TranslateService,
        private _ref: ChangeDetectorRef,
        private _service: ProjectExportService,
        private _dataService: DataService,
        private _pageService: PageService
    ) {}

    ngOnInit() {
        this.items = [
            { title: this.isFlatplan ? this._translate.instant("projects.flatplan.edit") : this._translate.instant("projects.flatplan.editExport"), action: ThumbnailMenuAction.Edit },
            {
                title: this.isFlatplan ? this._translate.instant("projects.flatplan.configurePage") : this._translate.instant("projects.flatplan.configureExport"),
                action: ThumbnailMenuAction.UpdatePage,
            },
            { title: this.isFlatplan ? this._translate.instant("projects.flatplan.deletePage") : this._translate.instant("projects.flatplan.deleteExport"), action: ThumbnailMenuAction.PageDelete },
        ];

        this.itemsWithoutEdit = [
            {
                title: this.isFlatplan ? this._translate.instant("projects.flatplan.configurePage") : this._translate.instant("projects.flatplan.configureExport"),
                action: ThumbnailMenuAction.UpdatePage,
            },
            { title: this.isFlatplan ? this._translate.instant("projects.flatplan.deletePage") : this._translate.instant("projects.flatplan.deleteExport"), action: ThumbnailMenuAction.PageDelete },
        ];

        this.acl = this._authService.getUserAclFromToken();

        this.headers = [this._translate.instant("table.pageName"), this._translate.instant("general.products"), this._translate.instant("table.template"), this._translate.instant("table.numberPage")];

        if (this.isFlatplan) {
            this.headers.push(this._translate.instant("table.folio"));
        }

        this.headers.push(this._translate.instant("table.actions"));

        this._updateView();
        this.groups.forEach((page) => {
            this.elementStates[page.id] = false;
        });
    }

    ngOnDestroy() {}

    ngOnChanges() {
        this._updateView();
    }

    ngAfterViewChecked(): void {
        this._updateView();
    }

    /**
     * Emit an event when an page action is triggered
     * @param event
     * @param page
     */
    onAction(event: any, page: any): void {
        this.pageAction.emit({ data: page, action: event });
    }

    /**
     * Update the view
     */
    private _updateView(): void {
        this.pagesBase = this.pages;
        const groups = [];
        let currentGroup = "";
        let group = null;
        this.pages.forEach((page, index) => {
            let newGroup = page.pageDuplicateId ? page.pageDuplicateId : page.group;

            if (currentGroup !== newGroup) {
                currentGroup = newGroup;
                group = { ...page };
                group.nbpage = 1;
                group.folio = { start: index + 1, end: index + 1 };
                if (group !== null) {
                    groups.push(group);
                }
            } else {
                group.nbpage++;
                group.folio.end++;
            }
        });
        this.groups = groups;
    }

    private _pagesMoved(): void {
        this.movePage.emit(this.pagesBase.map((p) => p.id));
    }

    sort(event) {
        const oldIndex = event.dragIndex;
        const newIndex = event.dropIndex;

        const newPages = [];

        this.groups.forEach((p) => {
            newPages.push(this.pagesBase.filter((pB) => pB.name === p.name));
        });

        let current = newPages[newIndex];

        newPages[newIndex] = newPages[oldIndex];

        if (newIndex < oldIndex) {
            for (let i = newIndex + 1; i <= oldIndex; i++) {
                const tmp = newPages[i];
                newPages[i] = current;
                current = tmp;
            }
        } else {
            for (let i = newIndex - 1; i >= oldIndex; i--) {
                const tmp = newPages[i];
                newPages[i] = current;
                current = tmp;
            }
        }

        this.pages = [];
        newPages.forEach((t) => {
            this.pages = this.pages.concat(t);
        });

        this._updateView();
        this._pagesMoved();
        this._ref.markForCheck();
    }

    onPreview(page) {
        this.pageAction.emit({ data: page, action: ThumbnailMenuAction.Preview });
    }

    getIdsForArchive(archiveHd: boolean = false) {
        let data = this._dataService.getIdsForArchive(this._selectedRows, "page", archiveHd, "heading");

        this._pageService.downloadArchive(data);
    }

    /**
     * Remove one row from selected
     * @param rowHeading
     */
    removeFromSelection(rowHeading: string): void {
        this._selectedRows = this._dataService.removeFromSelection(this._selectedRows, rowHeading, "heading");
    }
}
