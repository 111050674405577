import { Deserializable } from "../../shared/models/deserializable";

export class Thumb implements Deserializable {
    public id: number;
    public name: string;
    public extension: string;
    public thumb: string;
    public thumbPath: string;
    public tags: any[];
    public path: string;
    public picto: boolean;
    public model: { id: number; name: string };
    public children: any[];
    public dateCreate: string;
    public dateUpdate: string;
    public isExpired: boolean;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}
