import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { faClock, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { SpinnerService } from "app/core/services/global/spinner/spinner.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { PageTextTabService } from "app/core/services/project/export/page-text-tab.service";
import { ExportElementValue } from "app/shared/models/project/export-element.model";

@Component({
    selector: "app-edit-fieldset",
    templateUrl: "./edit-fieldset.component.html",
})
export class EditFieldsetComponent implements OnInit {
    @Input() field: any;
    @Input() disabled: boolean;
    @Input() pageDuplicateId: boolean;
    readonly faClock = faClock;
    readonly faPlus = faPlusCircle;
    readonly faTrash = faTrash;
    blockList: boolean = false;

    @Output() historyClick = new EventEmitter<any>();

    /**
     * Sortable option to reorder collections
     */
    readonly sortableJSOptions = {
        ghostClass: "placeholder",
        handle: ".sortable",
        selectedClass: "selected", // The class applied to the selected items
        onEnd: function (event: any) {
            function array_move(arr: any[], oldIndex: number, newIndex: number): void {
                while (oldIndex < 0) {
                    oldIndex += arr.length;
                }
                while (newIndex < 0) {
                    newIndex += arr.length;
                }
                if (newIndex >= arr.length) {
                    var k = newIndex - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
            }

            const from = event.oldIndex;
            const to = event.newIndex;

            array_move(this.field.value, from, to);
            this._saveValue(this.field, true, 1);
        }.bind(this),
    };

    private _exportId: number;
    private _pageId: number;
    private version: number;

    constructor(
        private _modalService: SwalModalService,
        private _loader: SpinnerService,
        private _pageService: PageTextTabService,
        private _route: ActivatedRoute,
        private _toaster: ToastService,
        private _translate: TranslateService
    ) {
    }

    ngOnInit(): void {
        this._exportId = this._route.parent.parent.parent.snapshot.params.id;
        this._pageId = this._route.snapshot.params.id;
        this.version = this._route.queryParams["_value"]["version"];
    }

    /**
     * Update a collection value
     *
     * @param newValue
     * @param field
     */
    updateValue(newValue: string, field: ExportElementValue): void {
        field.value = newValue;
        this._saveValue(field, false);
    }

    /**
     * Update a value list in a collection
     *
     * @param newValue
     * @param field
     */
    updateValueList(newValue: any, field: ExportElementValue): void {
        if(!field.valueProjectId){
            this.blockList = true;
        }
        field.value = newValue instanceof Array ? newValue : [newValue];
        this._saveValue(field, false);
    }

    /**
     * Save value
     *
     * @param field
     */
    private _saveValue(field: any, isAddCollection: boolean = false, col?: number) {
        this._loader.disable();
        field.isLoading = true;
        if (!field.countRequest) {
            field.countRequest = 1;
        } else {
            field.countRequest++;
        }

        if (typeof field.value === "string") {
            field.value = field.value.trim();
        }
        let value = field.value;
        if (field.fieldType == "checkbox") {
            value = value ? 1 : 0;
        }
        if (col) {
            value = {
                idLastCol: col,
                collections: field.value,
            };
        }
        if (field.fieldType == "fieldset" || field.fieldType == "list multiple") {
            value = JSON.stringify(value);
        }
        this._pageService
            .saveValue(
                value,
                this._exportId,
                field.valueProjectId,
                this.field.elemTypeFieldId,
                this.field.productId,
                this.field.variableProjectId,
                this._pageId,
                this.version,
                this.pageDuplicateId,
                field.valuePimId ? field.valuePimId : -1,
                field.variableProjectId,
                field.collection,
                field.pimInputId,
                isAddCollection
            )
            .subscribe(
                (data) => {
                    this.blockList = false;
                    field.countRequest--;
                    if (field.countRequest === 0) {
                        this._loader.activate();
                        field.isLoading = false;
                        field.valueProjectId = data.body.data.id;
                        if(field.fieldType == "list multiple" && typeof data.body.data.value === "string" ){
                            field.value = JSON.parse(data.body.data.value);
                        }else {
                            field.value = data.body.data.value;
                        }
                        
                        this._toaster.show({
                            message: this._translate.instant("general.savedfield", {key: field.key || field.name}),
                            type: "success",
                        });
                    }
                },
                () => {
                    field.countRequest--;
                    if (field.countRequest === 0) {
                        this._loader.activate();
                        field.isLoading = false;
                        this._toaster.show({
                            message: this._translate.instant("general.nosavedfield", {key: field.key || field.name}),
                            type: "danger",
                        });
                    }
                }
            );
    }

    checkIfNumber(event: any) {
        if (event.which !== 8 && isNaN(Number(String.fromCharCode(event.which)))) {
            event.preventDefault(); //stop character from entering input
        }
    }

    emitHistory(id: number, type: string): void {
        this.historyClick.emit({objectId: id, fieldType: type, tableHistoryFromFieldset: true});
    }

    /**
     * Emit event to add collection
     *
     * @param field
     * @param nav
     */
    addCollection(field: any, nav: any): void {

        this.field.value.push(field.fieldset);
        let col = "0";
        for (let index = 0; index < this.field.fieldset.length; index++) {
            col = this.field.fieldset[index].collection;
            this.field.fieldset[index].collection = parseInt(col) + 1;
        }

        this._saveValue(this.field, true, parseInt(col) + 1);
        setTimeout(() => nav.select(nav.items._results.length - 1));
    }

    /**
     * Emit event to remove collection
     *
     * @param event
     * @param field
     */
    removeCollection(event: MouseEvent, index: number, nav: any): void {
        this._modalService.deleteWithContent("modal.mediadam.deleteDm").then((result) => {
            if (result.value) {
                let col = this.field.fieldset[0].collection;

                this.field.value.splice(index, 1);

                this._saveValue(this.field, true, parseInt(col));
                setTimeout(() => nav.select(nav.items._results.length - 1));
            }
        });
        event.preventDefault();
        event.stopImmediatePropagation();
    }
}
